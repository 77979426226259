var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-row mb-12"},[(!_vm.detail)?_c('div',{class:[
      _vm.detail ? _vm.detailShow + '  w-full mb-base' : '',
      _vm.detailHide,
    ]},[_c('vs-table',{attrs:{"search":"","stripe":"","border":"","description":"","sst":true,"data":_vm.table.data,"max-items":_vm.table.length,"total":_vm.table.total,"not-click-selected":"","open-expand-only-td":""},on:{"search":_vm.handleSearch,"change-page":_vm.handleChangePage,"sort":_vm.handleSort},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,class:data[indextr].class,attrs:{"data":tr}},[_c('vs-td',{staticClass:"whitespace-no-wrap"},[[_c('vx-tooltip',{attrs:{"text":"Edit Template"}},[_c('vs-button',{attrs:{"type":"line","icon-pack":"feather","icon":"icon-edit"},on:{"click":function($event){$event.stopPropagation();return _vm.handleEdit(
                      data[indextr].id,
                      indextr,
                      data[indextr]
                    )}}})],1),_c('vx-tooltip',{attrs:{"text":"Delete Template"}},[_c('vs-button',{attrs:{"color":"danger","type":"line","icon-pack":"feather","icon":"icon-x-circle"},on:{"click":function($event){return _vm.deleteDraft(data[indextr].id)}}})],1)]],2),_c('vs-td',[_c('a',{attrs:{"href":data[indextr].file_url}},[_vm._v(_vm._s((data[indextr].file_name)))])]),_c('vs-td',[_vm._v(" "+_vm._s((data[indextr].Code))+"-"+_vm._s((data[indextr].Name))+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.dateFormat(data[indextr].created_at))+" ")]),_c('vs-td',[_vm._v(" "+_vm._s((data[indextr].status))+" ")])],1)})}}],null,false,1568483063),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"header"},[_c('vs-dropdown',{staticClass:"cursor-pointer",attrs:{"vs-trigger-click":""}},[_c('div',{staticClass:"p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(this.table.start)+" - "+_vm._s(this.table.end)+" of "+_vm._s(this.table.total))]),_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","svgClasses":"h-4 w-4"}})],1),_c('vs-dropdown-menu',_vm._l((_vm.table.limits),function(item){return _c('vs-dropdown-item',{key:item,on:{"click":function($event){return _vm.handleChangelength(item)}}},[_c('span',[_vm._v(_vm._s(item))])])}),1)],1)],1),_c('template',{slot:"thead"},[_c('vs-th',[_vm._v("Action")]),_c('vs-th',[_vm._v("File Template")]),_c('vs-th',[_vm._v("Supplier")]),_c('vs-th',{attrs:{"sort-key":"created_at"}},[_vm._v("Created Date")]),_c('vs-th',{attrs:{"sort-key":"status"}},[_vm._v("Status")])],1)],2),_c('vs-pagination',{staticStyle:{"padding-top":"5px"},attrs:{"total":_vm.table.totalPage},model:{value:(_vm.setPage),callback:function ($$v) {_vm.setPage=$$v},expression:"setPage"}})],1):_vm._e(),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
        _vm.detail ? _vm.detailShow + '  w-full mb-base' : '',
        _vm.detailHide,
      ]},[_c('vs-row',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{staticClass:"ml-auto",attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.handleClose}},[_vm._v("Close ")])],1),(_vm.tID)?_c('detail',{attrs:{"tID":_vm.tID,"data-template":_vm.dataTemplate,"page-count":_vm.pageCount},on:{"close":_vm.handleClose,"update:dataValid":function($event){_vm.dataValid = $event}}}):_vm._e()],1)],1):_vm._e()]),_c('div',[_c('vs-prompt',{attrs:{"color":"danger","title":"Confirmation","active":_vm.activePromptDelete},on:{"cancel":function($event){_vm.val = ''},"accept":_vm.doDelete,"close":_vm.closeDelete,"update:active":function($event){_vm.activePromptDelete=$event}}},[_c('div',{staticClass:"vx-row mb-2"}),_c('div',{staticClass:"con-exemple-prompt"},[_vm._v(" Are you sure to Delete Draft invoice : "+_vm._s(_vm.tID)+" ? ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }