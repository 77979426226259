var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{attrs:{"title":"Template OCR"}},[_c('vs-button',{staticClass:"mb-2",on:{"click":_vm.handleCreateNew}},[_vm._v("Create Template")]),_c('div',{staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('span',[_vm._v("Supplier")]),_c('multiselect',{staticClass:"selectExample",attrs:{"options":_vm.optionSupplier,"multiple":false,"allow-empty":true,"group-select":false,"max-height":160,"limit":4,"placeholder":" Type to search","track-by":"ID","label":"Name","disabled":false},scopedSlots:_vm._u([{key:"singleLabel",fn:function(dt){return [_c('span',{staticClass:"option__desc"},[_c('span',{staticClass:"option__title"},[_vm._v(_vm._s(dt.option.Code!=="" ? `${dt.option.Code}-${ dt.option.Name }`: `${dt.option.Name}`)+" ")])])]}},{key:"option",fn:function(dt){return [_c('div',{staticClass:"option__desc"},[_c('span',{staticClass:"option__title"},[_vm._v(_vm._s(dt.option.Code!=="" ? `${dt.option.Code}-${ dt.option.Name }`: `${dt.option.Name}`)+" ")])])]}}]),model:{value:(_vm.filter_supplier),callback:function ($$v) {_vm.filter_supplier=$$v},expression:"filter_supplier"}}),(_vm.filter_supplier == null && _vm.status_submit)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("This field is required")]):_vm._e()],1)]),_c('div',{staticClass:"vx-row mb-12"},[(!_vm.detail)?_c('div',{class:[
      _vm.detail ? _vm.detailShow + '  w-full mb-base' : '',
      _vm.detailHide,
    ]},[_c('div',{staticClass:"tab-text"},[_c('create',{key:_vm.componentKey,attrs:{"data-selected":_vm.selectedTamplate,"detail-edit":_vm.detailEdit,"supplier_code":_vm.supplier_code},on:{"open_upload":_vm.handleUpload}})],1)]):_vm._e(),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
        _vm.detail ? _vm.detailShow + '  w-full mb-base' : '',
        _vm.detailHide,
      ]},[_c('vs-row',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{staticClass:"ml-auto",attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.handleClose}},[_vm._v("Close ")])],1),_c('form-create',{attrs:{"data-selected":_vm.selectedTamplate},on:{"close":_vm.handleClose,"close-edit":_vm.handleCloseToEdit}})],1)],1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }