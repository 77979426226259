<template>
  <div class="vx-row mb-12">

    <div v-if="!detail"
         v-bind:class="[
        detail ? detailShow + '  w-full mb-base' : '',
        detailHide,
      ]"
    >
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        v-model="selected"
        not-click-selected
        open-expand-only-td
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
            <vs-th >Action</vs-th>
            <vs-th >File Template</vs-th>
            <vs-th >Supplier</vs-th>
            <vs-th sort-key="created_at" >Created Date</vs-th>
            <vs-th sort-key="status" >Status</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
            :class="data[indextr].class"
          >
            <vs-td class="whitespace-no-wrap">
              <template>
                <vx-tooltip text="Edit Template">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    @click.stop="
                      handleEdit(
                        data[indextr].id,
                        indextr,
                        data[indextr]
                      )
                    "
                    icon="icon-edit"
                  />
                </vx-tooltip>
                <vx-tooltip text="Delete Template">
                  <vs-button
                    color="danger"
                    type="line"
                    icon-pack="feather"
                    @click="deleteDraft(data[indextr].id)"
                    icon="icon-x-circle"
                  />
                </vx-tooltip>
              </template>
            </vs-td>
            <vs-td>
              <a :href="data[indextr].file_url">{{  (data[indextr].file_name) }}</a>
            </vs-td>
            <vs-td>
              {{  (data[indextr].Code) }}-{{  (data[indextr].Name) }}
            </vs-td>
            <vs-td>
              {{  dateFormat(data[indextr].created_at) }}
            </vs-td>
            <vs-td>
              {{  (data[indextr].status) }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
    <!-- form -->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[
          detail ? detailShow + '  w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
            >Close
            </vs-button
            >
          </vs-col>

          <detail
            v-if="tID"
            @close="handleClose"
            :tID="tID"
            :data-template="dataTemplate"
            @update:dataValid="dataValid = $event"
            :page-count="pageCount"
          >
          </detail>
        </vs-row>
      </div>
    </transition>
    <div>
      <vs-prompt
        color="danger"
        title="Confirmation"
        @cancel="val = ''"
        @accept="doDelete"
        @close="closeDelete"
        :active.sync="activePromptDelete"
      >
        <div class="vx-row mb-2">
        </div>
        <div class="con-exemple-prompt">
          Are you sure to Delete Draft invoice : {{ tID }} ?
        </div>
      </vs-prompt>
    </div>
  </div>
</template>
<script>
import Detail from "./detail.vue";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Detail,
    Datepicker,
  },
  props: {
    detailEdit:{
       type: Boolean
    },
    dataSelected: {
      type: String
    },
    optionSupplier:{
        type: Object
    },
    supplier_code: {
      type: String,
    },
    from_inv_date: {
      type: String,
    },
    to_inv_date: {
      type: String,
    },
    from_due_date: {
      type: String,
    },
    to_due_date: {
      type: String,
    },
  },
  data() {
    return {

      selected: [],
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      statusView: false,
      deleteId: null,
      tID: null,
      fileName: null,
      dataItem: null,
      dataJson: null,
      dataAll: null,
      pageCount: 0,
      dataTemplate: null,
      invCode: "",
      vendor: null,
      poCode: null,
      index1: 0,
      warehouseCode: null,
      warehouseName: null,
      data: [],
      timeOut:null,
      table: {
        data: [],
        length: 5,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      },
      activePromptRelease : false,
      activePromptReject : false,
      activePromptCancel: false,
      activePromptDelete : false,
      activePrompt: false,
      activePromptReversal: false,
      createdDate: null,
      dueDate: null,
      invDate: null,
      trNumber: null,
      trDate: null,
      reversal_date: new Date(),
      disabledAfterToday: {
        from: new Date(), // Disable all dates after specific date
      },

      selectedItems: [],
      selectAll: false,
      isAllSelected: false,
      checked: [],
      selectable: true, // Enable row selection
    };
  },
  methods: {
    doDelete(){
      this.$vs.loading();
      this.$http
          .delete(`/api/v1/ocr/templates/supplier/${this.tID}`,null)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
    },
    closeDelete(){
      this.tID = null;
      this.activePromptDelete = false;
    },
    deleteDraft(id){
      this.tID = id;
      this.activePromptDelete = true;
    },
    dateFormat(date) {
      if (date) {
        const inputDate = String(date).trim();

        // Format YYYY-MM-DD
        if (inputDate.includes("-")) {
          return moment(inputDate).format("YYYY-MM-DD");

          // Format DD/MM/YYYY
        } else if (inputDate.includes("/")) {
          const parts = inputDate.split("/");
          if (parts.length === 3) {
            return moment(`${parts[2]}-${parts[1]}-${parts[0]}`).format("YYYY-MM-DD");
          } else {
            return "";
          }

          // Format DD MMMM YYYY
        } else if (/\d{2}\s\w+\s\d{4}/.test(inputDate)) {
          return moment(inputDate, "DD MMMM YYYY").format("YYYY-MM-DD");

          // Format DDMMYYYY or similar
        } else {
          if (inputDate.length >= 8) {
            const day = inputDate.substr(0, 2);
            const month = inputDate.substr(2, 2);
            const year = inputDate.substr(4, 4);

            const formattedDate = `${year}-${month}-${day}`;
            return moment(formattedDate).format("YYYY-MM-DD");
          } else {
            return "";
          }
        }
      }
      return "";
    },
    dateFormatGlobal (date) {
      if (date) {
        const inputDate = date;
        const parts = inputDate.split("/");
        if (parts.length === 3) {
          return `${parts[2]}-${parts[1]}-${parts[0]}`;
        }else {
          return ""
        }
      }else {
        return ""
      }
    },
    dateFormatDot (date) {
      if (date) {
        const inputDate = date;
        // Check if the input date string has enough characters
        if (inputDate.length >= 8) {
          // Extract day, month, and year from the input string
          const day = inputDate.substr(0, 2);
          const month = inputDate.substr(2, 2);
          const year = inputDate.substr(4, 4);

          // Rearrange the parts to form the desired format
          const formattedDate = `${year}-${month}-${day}`;

          return  formattedDate
        } else {
          return ""
        }
      }else {
        return ""
      }
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;

      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(()=>{
        this.getData();
      },1000)
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      console.log(active, "active")
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose(data) {

      this.getData();
      if (typeof(data) != "undefined" && data!=null &&  data.id != null) {
        this.$emit("open_upload",data);
        return
      }

      this.detail = false;
    },
    objectToKeyValueArray(obj) {
      var keyValueArray = [];
      if (obj===null) {
        return null
      }
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            var nestedKeyValueArray = this.objectToKeyValueArray(obj[key]);
            for (var i = 0; i < nestedKeyValueArray.length; i++) {
              var nestedKey = nestedKeyValueArray[i][0];
              var nestedValue = nestedKeyValueArray[i][1];
              keyValueArray.push([key + '.' + nestedKey, nestedValue]);
            }
          } else {
            keyValueArray.push([key, obj[key]]);
          }
        }
      }

      return keyValueArray;
    },
    shouldRemove(word) {
      let wordsToRemove = ["barang", "pajak", "harga", "jasa"];
      return wordsToRemove.includes(word);
    },
    findValueByPartialKey(arr, partialKey) {
      if (arr===null) {
        return  null
      }
      let filteredKeyValueArray = arr.filter(item => item[0].includes(partialKey));
      let arrs = filteredKeyValueArray.map(item => item[1]);
      return arrs.filter(item => item !== "" && !this.shouldRemove(item));
    },
    cleanString(valueArray, indexUse) {

      if (valueArray === null || valueArray.length === 0 || indexUse >= valueArray.length) {
        return "";
      }

      let value = valueArray[indexUse];
      return  value;
      /*if (value.trim() !== "") {
        return value.trim();
      } else {
        return "";
      }*/
    },
    removeNonAlphabeticCharacters(inputString) {
      // Use regular expression to replace non-alphabetic characters with an empty string
      return inputString.replace(/[^a-zA-Z]/g, '');
    },
      removeNonNumericCharacters(inputString) {
      // Use regular expression to replace non-numeric characters with an empty string
      return inputString.replace(/\D/g, '');
    },
      containsInteger(inputString) {
      return /\d/.test(inputString);
    },
    getData() {

      this.selectAll = false;
      this.$vs.loading();
      this.$http
        .get("/api/v1/ocr/templates/supplier", {
          params: {
            limit: this.table.length==0 ? 5 : this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            supplier_code: this.supplier_code,
            from_date: this.from_inv_date,
            to_date: this.to_inv_date,
          },
        })
        .then((resp) => {
          if (resp.code == "200") {
            this.table.total = resp.data.record_total;
            this.table.totalPage = resp.data.page_total;
            this.table.totalSearch = resp.data.record_total_search;
            this.table.length = resp.data.record_total_per_page;
            this.table.data = resp.data.records;
            this.data = resp.data.records;
            if (this.data !=null) {
              if (this.dataSelected) {
                this.detail = true;
                let indextr = -1;

                for (let i = 0; i < this.data.length; i++) {
                  if (this.data[i].id === this.dataSelected.id) {
                    indextr = i;
                    break;
                  }
                }
                if (indextr!== -1) {
                  this.handleEdit(
                      this.data[indextr].id,
                      indextr,
                      this.data[indextr]
                  );
                }
              }
            }
          }
          this.setStartEnd();
          this.$vs.loading.close();
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleCreate() {
      this.detail = true;
    },
    isJsonString(jsonData){
      if (!jsonData) return  {}
       if (jsonData.length > 2) {
            try {
                return JSON.parse(jsonData);
            }catch  {
                return {}
            }
       }
       return {}
    },
    handleEdit(
      id,
      index,
      template
    ) {
      this.dataSelected = null;
      this.detailEdit =false;
      this.tID = id;
      this.dataTemplate = template;
      this.table.data = [];
      this.table.data = this.data;

      this.table.data[this.index1].class = "";
      this.table.data[index].class = "highlight";
      this.detail = true;
      this.index1 = index;
    },
  },
  mounted() {
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {},
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}
</style>
