<template>
  <vx-card title="Template OCR">
    <vs-button class="mb-2" @click="handleCreateNew">Create Template</vs-button>
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Supplier</span>
        <multiselect
            class="selectExample"
            v-model="filter_supplier"
            :options="optionSupplier"
            :multiple="false"
            :allow-empty="true"
            :group-select="false"
            :max-height="160"
            :limit="4"
            placeholder=" Type to search"
            track-by="ID"
            label="Name"
            :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span  class="option__title">{{ dt.option.Code!=="" ? `${dt.option.Code}-${ dt.option.Name }`: `${dt.option.Name}` }} </span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span  class="option__title">{{ dt.option.Code!=="" ? `${dt.option.Code}-${ dt.option.Name }`: `${dt.option.Name}` }} </span>
            </div>
          </template>
        </multiselect>
        <span
            class="text-danger text-sm"
            v-if="filter_supplier == null && status_submit"
        >This field is required</span>
      </div>
    </div>
    <div class="vx-row mb-12">
        <div v-if="!detail"
             v-bind:class="[
        detail ? detailShow + '  w-full mb-base' : '',
        detailHide,
      ]"
        >
            <div class="tab-text">
              <create
                  :key="componentKey"
                  @open_upload="handleUpload"
                  :data-selected="selectedTamplate"
                  :detail-edit="detailEdit"
                  :supplier_code="supplier_code"
              ></create>
            </div>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
          detail ? detailShow + '  w-full mb-base' : '',
          detailHide,
        ]"
        >
          <vs-row>
            <vs-col
                style="padding-bottom: 8px"
                vs-offset="8"
                vs-type="flex"
                vs-justify="rigth"
                vs-align="rigth"
                vs-w="4"
            >
              <vs-button
                  class="ml-auto"
                  size="small"
                  v-on:click="handleClose"
                  color="grey"
                  icon-pack="feather"
                  icon="icon-x-square"
              >Close
              </vs-button>
            </vs-col>
            <!-- form component -->
            <form-create @close="handleClose" @close-edit="handleCloseToEdit" :data-selected="selectedTamplate"></form-create>
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import Create from "./create"
import FormCreate from "./form-create";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: {
      Create,
    FormCreate,
    Datepicker,
  },
  data() {
    return {
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      detailEdit: false,
      componentKey: 0,
      status_submit: false,
      optionSupplier: [],
      filter_supplier: null,
      selectedTamplate:null
    };
  },
  methods: {
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("dddd, MMMM Do YYYY");
      }
      return a;
    },
    getDatabyID(){
      this.$http
          .get("/api/v1/ocr/invoice/1", null)
          .then((resp) => {
            console.log("resp",resp)
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
    },
    getOptionSupplier() {
      this.$vs.loading();
      this.$http
          .get("/api/v1/master/suppliers", {
            params: {
              order: "name",
              sort: "asc",
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              let allSupplier=  {
                "ID": 0,
                "Code": "",
                "ShortName": "",
                "Name": "ALL SUPPLIER",
                "Address": "",
                "City": "",
                "ContactName": "",
                "ContactMobile": "",
                "Phone": "",
                "PaymentTermID": 0,
              }

              this.optionSupplier = [allSupplier, ...resp.data.records];
            } else {
              this.$vs.notify({
                title: "Error",
                text: "Failed to get Supplier option",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            }
            this.$vs.loading.close();
          });
    },
    handleClose(data) {
      this.detail = false;
      this.componentKey += 1;
      if (data.id != null) {
        this.selectedTamplate = data;
        this.detailEdit = true;
      }
    },
    handleCloseToEdit(data){
      this.handleClose(data);
    },
    handleCreateNew(){
      this.selectedTamplate = null;
      this.handleOpenForm()
    },
    handleOpenForm() {
      this.detail = true;
      this.detailEdit = false;
      this.status_detail = "form-create";
    },
    handleUpload(data){
      this.selectedTamplate = data;
      this.detailEdit = false;
      this.handleOpenForm();
    }
  },
  mounted() {
    this.getOptionSupplier();
  },
  watch: {
    filter_supplier: function () {
      if (this.filter_supplier != null) {
        this.detailEdit = false;
        this.selectedTamplate = null;
        this.supplier_code = this.filter_supplier.Code;
      } else {
        this.supplier_code = null;
      }
      this.componentKey += 1;
    },
  },
};
</script>
<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}

.reportrange-text {
  min-height: 40px !important;
}
</style>
