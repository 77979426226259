<template>
  <div v-if="templateCurrent !==null" class=" vx-row w-full  ml-auto mr-auto">
    <div class="vx-col w-1/2">
        <span>
        <h4 class="mb-5 ">Template OCR    <br> <vs-button v-if="imageload"
            class="ml-auto mt-3 bg-primary"
            size="small"
            v-on:click="handleReplaceTemplate"
            icon-pack="feather"
        >Change Template File
      </vs-button></h4>
      </span>
      <div class="row bg bg-light" style="padding-bottom: 60px;">
        <div class="col-md-10 offset-md-1">
          <div class="flex-container">
            <div class="flex-item"  >

              <div class="content">
                <section class="cropper-area">
                  <div class="img-cropper">
                    <vue-cropper
                        ref="cropper"
                        :src="imgSrc"
                        preview=".preview"
                        :view-mode="1"
                        :auto-crop-area="0.5"
                        :ready="onCropperReady"
                        :cropend="handleCropMove"
                        :fixed="true"
                        :info="true"
                        :auto-crop="true"
                        :auto-crop-width="300"
                        :auto-crop-height="300"
                        :can-move="false"
                        :center-box="true"
                        :zoomable="false"
                        :rotatable="false"
                    />
                  </div>
                </section>
              </div>
              <div v-if="imageload" >
                <div class="vx-col w-[80%]">
                  <div class="showBox p-2">
                    <vs-button class="float-right ml-2" @click.stop="getOcr"> OCR</vs-button>
                    <div class="preview" />
                  </div>
                </div>
                <div class="vx-row mb-2 mt-6">
                  <div class="vx-col w-full"><b>Text OCR</b>
                    <vs-textarea
                        height="600px"
                        readonly=""
                        v-model="ocr_value"  />
                  </div>
                </div>
                <div class="vx-col w-[20%]">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vx-col w-1/2">
      <div v-if="templateCurrent !==null">
        <div class="w-full">
          <div class="w-full bold ">Supplier Name</div>
          <div class="vx-row mb-2">
            <div class="vx-col w-full">
              <vs-input
                  class="w-full "
                  v-model="dataTemplate.Name"
                  placeholder="text pattern"
                  readonly=""
                  disabled=""
              />
            </div>
          </div>
        </div>
        <div class="w-full">
          <div class="w-full bold "> Document Pattern</div>
          <div class="vx-row mb-2">
            <div class="vx-col w-full">
              <vs-input
                  class="w-full"
                  v-model="templateCurrent.all.pattern"
                  placeholder="text pattern"
              />

              <span
                  class="text-danger text-sm"
                  v-if="templateCurrent.all.pattern === '' && status_submit"
              >This field is required</span>
            </div>
          </div>
        </div>

        <vs-collapse accordion type="margin" class="p-2">
          <vs-collapse-item :open="true" class="p-3">
            <div slot="header">
              Invoice
            </div>
            <div class="vx-row"   >

              <vs-button class="mr-3 mb-2 float-right" @click.stop="setTemplateDefaultValue">Load Default Setting
              </vs-button>
              <div class="w-full">
                <vs-divider style="width: 100%; "><b>Header</b></vs-divider>
                <div class="vx-col w-full mb-2">
                  <span>Region Type : </span>
                  <div class="vx-col w-full mb-2 mt-2">
                    <vs-radio v-model="templateCurrent.invoice.header.region_types"
                               vs-value="full" class="mr-4" vs-name="invoice-header-type-hidden">Full Page
                    </vs-radio>
                    <vs-radio v-model="templateCurrent.invoice.header.region_types"
                               vs-value="image" class="mr-4" vs-name="invoice-header-type-hidden">Image Region
                    </vs-radio>
                    <vs-radio v-model="templateCurrent.invoice.header.region_types"
                               vs-value="text" class="mr-4" vs-name="invoice-header-type-hidden">Text Delimiter
                    </vs-radio>
                  </div>
                </div>
                <div v-if="templateCurrent.invoice.header.region_types==='image'" class="w-[0.9]  vx-col">
                  <div class="scroll-container" v-if="optionImageThub">
                    <img
                        class="m-1"
                        v-for="(src, index) in optionImageThub"
                        :key="index"
                        :src="src"
                        width="86px"
                        alt="page-1"
                        @click.stop="previewImage(index,'invoice.header')"
                        :class="{ selected: templateCurrent.invoice.header.page_number === index }"
                    />
                    <span v-if="!optionImageThub" class="box-loading">Empty image..</span>
                  </div>
                </div>
                <div v-else-if="templateCurrent.invoice.header.region_types==='text'" class="w-full">
                  <div class="w-full bold "> Text Pattern</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.invoice.header.start"
                          placeholder="start text pattern"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Row Count"
                          v-model="templateCurrent.invoice.header.lines"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          name="po_code"
                          v-model="templateCurrent.invoice.header.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>
                <vs-divider style="width: 100%; margin-left: 2%"></vs-divider>
                <div class="w-full">
                  <div class="w-full bold "> No. Invoice</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.invoice.no.start"
                          placeholder="start text pattern"

                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Delimiter"
                          v-model="templateCurrent.invoice.no.delimiter"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          name="po_code"
                          v-model="templateCurrent.invoice.no.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="w-full bold "> Invoice Date</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.invoice.dndate.start"
                          placeholder="start text pattern"

                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Delimiter"
                          v-model="templateCurrent.invoice.dndate.delimiter"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          name="po_code"
                          v-model="templateCurrent.invoice.dndate.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="w-full bold "> Due Date</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.invoice.duedate.start"
                          placeholder="start text pattern"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Delimiter"
                          v-model="templateCurrent.invoice.duedate.delimiter"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          name="po_code"
                          v-model="templateCurrent.invoice.duedate.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="w-full bold "> PO Code</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.invoice.po.start"
                          placeholder="start text pattern"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Delimiter"
                          v-model="templateCurrent.invoice.po.delimiter"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          name="po_code"
                          v-model="templateCurrent.invoice.po.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="w-full bold "> Ship To</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.invoice.shipto.start"
                          placeholder="start text pattern"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Delimiter"
                          v-model="templateCurrent.invoice.shipto.delimiter"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          v-model="templateCurrent.invoice.shipto.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="w-full bold "> D/N</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.invoice.dn.start"
                          placeholder="start text pattern"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Delimiter"
                          v-model="templateCurrent.invoice.dn.delimiter"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          name="po_code"
                          v-model="templateCurrent.invoice.dn.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full">
                <vs-divider style="width:100%"><b>Footer</b></vs-divider>
                <div  >
                  <span>Region Type : </span>
                  <div class="vx-col w-full mb-2 mt-2">
                    <vs-radio v-model="templateCurrent.invoice.footer.region_types"
                               vs-value="full" class="mr-4" vs-name="invoice-footer-type-hidden">Full Page
                    </vs-radio>
                    <vs-radio v-model="templateCurrent.invoice.footer.region_types"
                               vs-value="image" class="mr-4" vs-name="invoice-footer-type-hidden">Image Region
                    </vs-radio>
                    <vs-radio v-model="templateCurrent.invoice.footer.region_types"
                               vs-value="text" class="mr-4" vs-name="invoice-footer-type-hidden">Text Delimiter
                    </vs-radio>
                  </div>
                </div>
                <div v-if="templateCurrent.invoice.footer.region_types==='image'" class="w-[0.9]  vx-col">
                  <div class="scroll-container" v-if="optionImageThub">
                    <img
                        class="m-1"
                        v-for="(src, index) in optionImageThub"
                        :key="index"
                        :src="src"
                        width="86px"
                        alt="page-1"
                        @click.stop="previewImage(index,'invoice.footer' )"
                        :class="{ selected: templateCurrent.invoice.footer.page_number === index }"
                    />
                    <span v-if="!optionImageThub" class="box-loading">Empty image..</span>
                  </div>
                </div>
                <div v-else-if="templateCurrent.invoice.footer.region_types==='text'" class="w-full">
                  <div class="w-full bold "> Text Pattern</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.invoice.footer.start"
                          placeholder="start text pattern"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Row Count"
                          v-model="templateCurrent.invoice.footer.lines"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          v-model="templateCurrent.invoice.footer.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>
                <vs-divider style="width: 100%; margin-left: 2%"></vs-divider>
                <div class="w-full">
                  <div class="w-full bold "> Sub Total</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.invoice.sub_total.start"
                          placeholder="start text pattern"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Delimiter"
                          v-model="templateCurrent.invoice.sub_total.delimiter"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          name="po_code"
                          v-model="templateCurrent.invoice.sub_total.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="w-full bold "> Tax</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.invoice.tax.start"
                          placeholder="start text pattern"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Delimiter"
                          v-model="templateCurrent.invoice.tax.delimiter"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          name="po_code"
                          v-model="templateCurrent.invoice.tax.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="w-full bold "> Invoice Balance</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.invoice.invoice_balance.start"
                          placeholder="start text pattern"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Delimiter"
                          v-model="templateCurrent.invoice.invoice_balance.delimiter"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          name="po_code"
                          v-model="templateCurrent.invoice.invoice_balance.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vs-collapse-item>
          <vs-collapse-item class="p-3">
            <div slot="header">
              Faktur
            </div>
            <div class="vx-row"   >
              <div class="w-full">
                <vs-divider style="width: 100%"><b>Header</b></vs-divider>
                <div class="vx-col w-full mb-2">
                  <span>Region Type : </span>
                  <div class="vx-col w-full mb-2 mt-2">
                    <vs-radio v-model="templateCurrent.faktur.header.region_types"
                               vs-value="full" class="mr-4" vs-name="faktur-header-type-hidden">Full Page
                    </vs-radio>
                    <vs-radio v-model="templateCurrent.faktur.header.region_types"
                               vs-value="image" class="mr-4" vs-name="faktur-header-type-hidden">Image Region
                    </vs-radio>
                    <vs-radio v-model="templateCurrent.faktur.header.region_types"
                               vs-value="text" class="mr-4" vs-name="faktur-header-type-hidden">Text Delimiter
                    </vs-radio>
                  </div>
                </div>
                <div v-if="templateCurrent.faktur.header.region_types==='image'" class="w-[0.9]  vx-col">
                  <div class="scroll-container" v-if="optionImageThub">
                    <img
                        class="m-1"
                        v-for="(src, index) in optionImageThub"
                        :key="index"
                        :src="src"
                        width="86px"
                        alt="page-1"
                        @click.stop="previewImage(index,'faktur.header' )"
                        :class="{ selected: templateCurrent.faktur.header.page_number === index }"
                    />
                    <span v-if="!optionImageThub" class="box-loading">Empty image..</span>
                  </div>
                </div>
                <div v-else-if="templateCurrent.faktur.header.region_types==='text'" class="w-full">
                  <div class="w-full bold "> Text Pattern</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.faktur.header.start"
                          placeholder="start text pattern"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Row Count"
                          v-model="templateCurrent.faktur.header.lines"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          name="po_code"
                          v-model="templateCurrent.faktur.header.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>
                <vs-divider style="width: 100%; margin-left: 2%"></vs-divider>
                <div class="w-full">
                  <div class="w-full bold "> Nomor Seri Faktur Pajak</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.faktur.nomor_seri.start"
                          placeholder="start text pattern"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Delimiter"
                          v-model="templateCurrent.faktur.nomor_seri.delimiter"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          name="po_code"
                          v-model="templateCurrent.faktur.nomor_seri.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="w-full bold "> Nama</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.faktur.pengusaha_nama.start"
                          placeholder="start text pattern"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Delimiter"
                          v-model="templateCurrent.faktur.pengusaha_nama.delimiter"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          name="po_code"
                          v-model="templateCurrent.faktur.pengusaha_nama.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="w-full bold "> Alamat</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.faktur.pengusaha_alamat.start"
                          placeholder="start text pattern"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Delimiter"
                          v-model="templateCurrent.faktur.pengusaha_alamat.delimiter"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          name="po_code"
                          v-model="templateCurrent.faktur.pengusaha_alamat.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="w-full bold "> NPWP</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.faktur.pengusaha_npwp.start"
                          placeholder="start text pattern"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Delimiter"
                          v-model="templateCurrent.faktur.pengusaha_npwp.delimiter"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          name="po_code"
                          v-model="templateCurrent.faktur.pengusaha_npwp.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full">
                <vs-divider style="width: 100%"><b>Footer</b></vs-divider>
                <div>
                  <span>Region Type : </span>
                  <div class="vx-col w-full mb-2 mt-2">
                    <vs-radio v-model="templateCurrent.faktur.footer.region_types"
                               vs-value="full" class="mr-4" vs-name="faktur-footer-type-hidden">Full Page
                    </vs-radio>
                    <vs-radio v-model="templateCurrent.faktur.footer.region_types"
                               vs-value="image" class="mr-4" vs-name="faktur-footer-type-hidden">Image Region
                    </vs-radio>
                    <vs-radio v-model="templateCurrent.faktur.footer.region_types"
                               vs-value="text" class="mr-4" vs-name="faktur-footer-type-hidden">Text Delimiter
                    </vs-radio>
                  </div>
                </div>
                <div v-if="templateCurrent.faktur.footer.region_types==='image'" class="w-[0.9]  vx-col">
                  <div class="scroll-container" v-if="optionImageThub">
                    <img
                        class="m-1"
                        v-for="(src, index) in optionImageThub"
                        :key="index"
                        :src="src"
                        width="86px"
                        alt="page-1"
                        @click.stop="previewImage(index,'faktur.footer' )"
                        :class="{ selected: templateCurrent.faktur.footer.page_number === index }"
                    />
                    <span v-if="!optionImageThub" class="box-loading">Empty image..</span>
                  </div>
                </div>
                <div v-else-if="templateCurrent.faktur.footer.region_types==='text'" class="w-full">
                  <div class="w-full bold "> Text Pattern</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.faktur.footer.start"
                          placeholder="start text pattern"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Row Count"
                          v-model="templateCurrent.faktur.footer.lines"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          v-model="templateCurrent.faktur.footer.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>
                <vs-divider style="width: 100%; margin-left: 2%"></vs-divider>
                <div class="w-full">
                  <div class="w-full bold "> Harga Jual / Penggantian</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.faktur.penggantian.start"
                          placeholder="start text pattern"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Delimiter"
                          v-model="templateCurrent.faktur.penggantian.delimiter"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          name="po_code"
                          v-model="templateCurrent.faktur.penggantian.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="w-full bold "> Dikurangi Potongan Harga</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.faktur.dph.start"
                          placeholder="start text pattern"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Delimiter"
                          v-model="templateCurrent.faktur.dph.delimiter"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          name="po_code"
                          v-model="templateCurrent.faktur.dph.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="w-full bold "> Dasar Pengenaan Pajak</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.faktur.dpp.start"
                          placeholder="start text pattern"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Delimiter"
                          v-model="templateCurrent.faktur.dpp.delimiter"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          v-model="templateCurrent.faktur.dpp.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="w-full bold "> Total PPnBM</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.faktur.ppnbm.start"
                          placeholder="start text pattern"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Delimiter"
                          v-model="templateCurrent.faktur.ppnbm.delimiter"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          v-model="templateCurrent.faktur.ppnbm.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="w-full bold "> Total PPN</div>
                  <div class="vx-row mb-2">
                    <div class="vx-col w-1/3">
                      <vs-input
                          class="w-full "
                          label="Start Text"
                          v-model="templateCurrent.faktur.ppn.start"
                          placeholder="start text pattern"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="Delimiter"
                          v-model="templateCurrent.faktur.ppn.delimiter"
                          placeholder="delimiter text"
                      />
                    </div>
                    <div class="vx-col w-1/3 mb-2">
                      <vs-input
                          class="w-full "
                          label="End Text"
                          v-model="templateCurrent.faktur.ppn.end"
                          placeholder="end text pattern"
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </vs-collapse-item>
        </vs-collapse>
      </div>
      <span>
      <h4 class="mb-5"></h4>
    </span>
      <div class="vx-row mb-2 mt-6 p-2" v-if="imageload" >
        <div class="vx-col w-full">
          <vs-button class="mr-3 mb-2" @click.stop="handleUpdate">Update
          </vs-button>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import {VueCutter} from 'vue-cutter'
import {awint, Int } from "vuecode.js";
import moment from "moment";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  components: {
    Datepicker,
    VueCutter,
    VueCropper
  },
  props: {
    dataItem: {
      type: [],
    },
    fileName: {
      type: String,
    },
    dataJSON: {
      type: Object
    },
    dataAll: {
      type: Object
    },
    page: {
      type: Int,
    },
    pageCount: {
      type: Int,
    },
    dataTemplate: {
      type: Object
    },
    dataSupplier: {
      type: String
    },
    tID: {
      type: Number,
    },
    ocr_var: {
      type: String,
    },
  },
  created() {
    this.selectedPages = [this.pageOptions[0]];
  },
  data() {
    return this.initialState();
  },
  methods: {
    getValueFromObject(objJson, keyVar) {
      if (objJson && typeof objJson !== 'undefined' && objJson !== "") {
        let jsonData = JSON.parse(objJson)
        return jsonData[keyVar]
      }
      return ""
    },
    formatCurrency(value) {
      return this.numberFormat(value)
    },
    numberFormat(number) {
      if (number == null || typeof number === "undefined") {
        return "0"
      }
      var strNumber = parseFloat(number.toString().replace(",", ".")).toFixed(2);
      var formatter = new Intl.NumberFormat('id-ID');
      return formatter.format(strNumber) === "NaN" ? "0" : formatter.format(strNumber).toString().replaceAll(".", ",") + ".00";

    },
    setFocusedInput(varName) {
      this.ocr_var = varName;
    },
    handleNumericInput(event, idx) {
      setTimeout(() => {
        this.dataTemplate[idx] = event.replace(/\D/g, "");
      }, 10);
    },
    previewImage(idx,reg_name ) {
      this.optionCutter.src = this.optionImageThub[idx];
      this.imgSrc = this.optionImageThub[idx];


      this.$refs.cropper.replace(this.imgSrc);
      this.selectedImageIndex = idx;
      this.regName = reg_name;
      this.data = JSON.stringify(this.regions[this.regName]);

      if (reg_name === "invoice.footer") {
        this.templateCurrent.invoice.footer.page_number=idx
      }else  if (reg_name === "invoice.header") {
        this.templateCurrent.invoice.header.page_number=idx
      }else if (reg_name === "faktur.footer") {
        this.templateCurrent.faktur.footer.page_number=idx
      }else  if (reg_name === "faktur.header") {
        this.templateCurrent.faktur.header.page_number=idx
      }


      this.setData();

    },
    getOcr() {

      let varData = this.ocr_var
      this.$refs.cropper.getCroppedCanvas().toBlob(blob => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
              let image64 =  reader.result.split(",")
              this.getTextOcr(image64[1], varData)

          };
      });

      this.getData();
    },
    getQR() {
      this.$refs.cutter.getBase64Data(data => {
        let image64 = data.split(",")
        this.getTextQR(image64[1])
      })
    },
    handleSelectionChangePage(newValues) {
      this.getPdfS3Image(newValues.page);
    },
    customLabelPage(option) {
      return `Page ${option.page}`;
    },
    addDays(dateStr, days) {
      return moment(dateStr).add(days, 'days').format('YYYY-MM-DD');
    },
    setTemplateFields(templateType, fields) {
      fields.forEach(field => {
        this.templateCurrent[templateType][field.name] = {
          start: "",
          delimiter: "",
          end: "",
          ...(field.lines && {lines: field.lines}),
          ...(field.page_number && {page_number: field.page_number}),
          ...(field.region_types && {region_types: field.region_types})
        };
      });
    },
    setTemplateDefaultValue() {
       this.templateCurrent["invoice"]["header"] = {
        start: "",
        delimiter: "",
        lines: 5,
        page_number: 5,
        end: "",
        region_types: "image",
        x: 600,
        y: 0,
        width: 1200,
        height: 430,
      }
      this.templateCurrent["invoice"]["footer"] = {
        start: "",
        delimiter: "",
        lines: 5,
        page_number: 5,
        end: "",
        region_types: "image",
        x: 600,
        y: 0,
        width: 600,
        height: 1200,
      }
      this.templateCurrent["invoice"]["no"] = {
        start: "No :",
        delimiter: "SPACE",
        end: "ENTER",
      }
      this.templateCurrent["invoice"]["duedate"] = {
        start: "DUE DATE",
        delimiter: "DELIMITER_DD",
        end: "ENTER",
      }
      this.templateCurrent["invoice"]["dndate"] = {
        start: "Date",
        delimiter: "DELIMITER_DD",
        end: "ENTER",
      }
      this.templateCurrent["invoice"]["po"] = {
        start: "PO :",
        delimiter: "SPACE",
        end: "ENTER",
      }

      this.templateCurrent["invoice"]["shipto"] = {
        start: "SHIPTO",
        delimiter: "DELIMITER_DD",
        end: "ENTER",
      }
      this.templateCurrent["invoice"]["dn"] = {
        start: "D/N",
        delimiter: "SPACE",
        end: "ENTER",
      }

      this.templateCurrent["invoice"]["invoice_balance"] = {
        start: "Invoice Balance",
        delimiter: "ENTER",
        end: "SPACE",
      }
      this.templateCurrent["invoice"]["sub_total"] = {
        start: "Sub Total",
        delimiter: "ENTER",
        end: "SPACE",
      }
      this.templateCurrent["invoice"]["tax"] = {
        start: "Tax",
        delimiter: "ENTER",
        end: "SPACE",
      }
      this.templateCurrent["faktur"]["header"] = {
        start: "",
        delimiter: "",
        lines: 5,
        page_number: 5,
        end: "",
        region_types: "full",
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      }
      this.templateCurrent["faktur"]["footer"] = {
        start: "Harga Jual /",
        delimiter: "",
        lines: 5,
        page_number: 5,
        end: "",
        region_types: "text",
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      }
      this.templateCurrent["faktur"]["nomor_seri"] = {
        start: "Nomor Seri Faktur Pajak",
        delimiter: "DELIMITER_DD",
        end: "ENTER",
      }
      this.templateCurrent["faktur"]["pembeli_nama"] = {
        start: "Nama",
        delimiter: "DELIMITER_DD",
        end: "ENTER",
      }
      this.templateCurrent["faktur"]["pembeli_alamat"] = {
        start: "Alamat",
        delimiter: "DELIMITER_DD",
        end: "ENTER",
      }
      this.templateCurrent["faktur"]["pembeli_npwp"] = {
        start: "NPWP",
        delimiter: "DELIMITER_DD",
        end: "ENTER",
      }
      this.templateCurrent["faktur"]["pengusaha_alamat"] = {
        start: "Nama",
        delimiter: "DELIMITER_DD",
        end: "ENTER",
      }
      this.templateCurrent["faktur"]["pengusaha_nama"] = {
        start: "Alamat",
        delimiter: "DELIMITER_DD",
        end: "ENTER",
      }
      this.templateCurrent["faktur"]["pengusaha_npwp"] = {
        start: "NPWP",
        delimiter: "DELIMITER_DD",
        end: "ENTER",
      }
      this.templateCurrent["faktur"]["penggantian"] = {
        start: "Penggantian",
        delimiter: "DELIMITER_DD",
        end: ",00",
      }
      this.templateCurrent["faktur"]["dph"] = {
        start: "Harga",
        delimiter: "DELIMITER_DD",
        end: ",00",
      }
      this.templateCurrent["faktur"]["dpp"] = {
        start: "Pajak",
        delimiter: "DELIMITER_DD",
        end: ",00",
      }
      this.templateCurrent["faktur"]["ppnbm"] = {
        start: "PPnBM",
        delimiter: "DELIMITER_DD",
        end: ",00",
      }
      this.templateCurrent["faktur"]["ppn"] = {
        start: "PPN ",
        delimiter: "DELIMITER_DD",
        end: ",00",
      }

      const createRegion = (template, section) => ({
        x: template[section].x,
        y: template[section].y,
        width: template[section].width,
        height: template[section].height,
        scaleX: 1,
        scaleY: 1,
      });

      this.regions = {
        "faktur.header": createRegion(this.templateCurrent.faktur, "header"),
        "faktur.footer": createRegion(this.templateCurrent.faktur, "footer"),
        "invoice.header": createRegion(this.templateCurrent.invoice, "header"),
        "invoice.footer": createRegion(this.templateCurrent.invoice, "footer"),
      };

      this.$forceUpdate()
    },
    setTemplateEmptyValue() {

        let emptyValue = {
            start: " ",
            delimiter: " ",
            end: " ",
        }

       this.templateCurrent["invoice"]["header"] = {
        start: "",
        delimiter: "",
        lines: 5,
        page_number: 5,
        end: "",
        region_types: "image",
        x: 600,
        y: 0,
        width: 1200,
        height: 430,
      }
      this.templateCurrent["invoice"]["footer"] = {
        start: "",
        delimiter: "",
        lines: 5,
        page_number: 5,
        end: "",
        region_types: "image",
        x: 600,
        y: 0,
        width: 600,
        height: 1200,
      }
      this.templateCurrent["invoice"]["no"] = emptyValue
      this.templateCurrent["invoice"]["duedate"] = emptyValue
      this.templateCurrent["invoice"]["dndate"] = emptyValue
      this.templateCurrent["invoice"]["po"] = emptyValue

      this.templateCurrent["invoice"]["shipto"] = emptyValue
      this.templateCurrent["invoice"]["dn"] = emptyValue

      this.templateCurrent["invoice"]["invoice_balance"] = emptyValue
      this.templateCurrent["invoice"]["sub_total"] = emptyValue
      this.templateCurrent["invoice"]["tax"] = emptyValue
      this.templateCurrent["faktur"]["header"] = {
        start: "",
        delimiter: "",
        lines: 5,
        page_number: 5,
        end: "",
        region_types: "full",
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      }
      this.templateCurrent["faktur"]["footer"] = {
        start: "",
        delimiter: "",
        end: "",
        lines: 5,
        page_number: 5,
        region_types: "text",
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      }
      this.templateCurrent["faktur"]["nomor_seri"] =emptyValue
      this.templateCurrent["faktur"]["pembeli_nama"] = emptyValue
      this.templateCurrent["faktur"]["pembeli_alamat"] = emptyValue
      this.templateCurrent["faktur"]["pembeli_npwp"] =emptyValue
      this.templateCurrent["faktur"]["pengusaha_alamat"] =emptyValue
      this.templateCurrent["faktur"]["pengusaha_nama"] = emptyValue
      this.templateCurrent["faktur"]["pengusaha_npwp"] = emptyValue
      this.templateCurrent["faktur"]["penggantian"] =emptyValue
      this.templateCurrent["faktur"]["dph"] =emptyValue
      this.templateCurrent["faktur"]["dpp"] =emptyValue
      this.templateCurrent["faktur"]["ppnbm"] = emptyValue
      this.templateCurrent["faktur"]["ppn"] =emptyValue

      const createRegion = (template, section) => ({
        x: template[section].x,
        y: template[section].y,
        width: template[section].width,
        height: template[section].height,
        scaleX: 1,
        scaleY: 1,
      });

      this.regions = {
        "faktur.header": createRegion(this.templateCurrent.faktur, "header"),
        "faktur.footer": createRegion(this.templateCurrent.faktur, "footer"),
        "invoice.header": createRegion(this.templateCurrent.invoice, "header"),
        "invoice.footer": createRegion(this.templateCurrent.invoice, "footer"),
      };
        this.setTemplateDefaultValue()



      this.$forceUpdate()
    },
    getOptionSupplier(idSupplier) {
      this.$http
          .get("/api/v1/master/supplier-payment-term", {
            params: {
              id: idSupplier
            },
            order: "name",
            sort: "asc",
          })
          .then((resp) => {
            if (resp.code === 200) {
              this.optionSupplier = resp.data;
            } else {
              this.$vs.notify({
                title: "Error",
                text: "Failed to get Supplier option",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            }
          });
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute('data-scale');
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute('data-scale', scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute('data-scale');
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute('data-scale', scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      let newLocation = this.$refs.cropper.getData()
      if (this.regName!=="") {
        this.regions[this.regName] = newLocation;
      }
      this.data = JSON.stringify(newLocation, null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;
      setTimeout(  ()=>{
        this.$refs.cropper.setData(JSON.parse(this.data));
        this.$forceUpdate()
      },100)
    },
    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    initialState() {
      return {
        imgSrc: null,
        regName: "",
        cropImg: '',
        data: null,
        ocr_value:null,
        selectedRole: null,
        selected: null,
        selectedImageIndex: -1,
        selectedBank: null,
        supplier: null,
        optionSupplier: null,
        selectedPages: [],
        pageOptions: Array.from({length: this.pageCount}, (_, index) => ({page: index + 1})),
        optionCutter: {
          bounding: '600px 950px',
          cropSize: '100px 200px',
          cropStart: '30px 0px',
          src: "",
          imageload: ''
        },
        regions: {
          "faktur.header" : {
            "x": 50,
            "y": 50,
            "width": 50,
            "height": 50,
            "scaleX": 1,
            "scaleY": 1
          },
          "faktur.footer" : {
            "x": 50,
            "y": 50,
            "width": 50,
            "height": 50,
            "scaleX": 1,
            "scaleY": 1
          },
          "invoice.header" : {
            "x": 50,
            "y": 50,
            "width": 50,
            "height": 50,
            "scaleX": 1,
            "scaleY": 1
          },
          "invoice.footer" : {
            "x": 50,
            "y": 50,
            "width": 50,
            "height": 50,
            "scaleX": 1,
            "scaleY": 1
          }
        },
        imageload: false,
        optionImageThub: null,
        showLoader: true,
        preHtml: '',
        remotePic: '',
        search: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        table: {
          data: [],
        },
        templateCurrent: null,
        templateJson: {
          invoice: {
            marker: {
              text: "invoice",
              x: 103,
              y: 129,
              width: 900,
              height: 100
            },
            header: [
              {
                name: "header-1",
                region_types: "image",
                page_number: 1,
                items: [
                  {
                    name: "no",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "No :",
                      end: "ENTER",
                      delimiter: "SPACE"
                    },
                    types: "TEXT"
                  },
                  {
                    name: "dn",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "D/N",
                      end: "ENTER",
                      delimiter: "DELIMITER_DD"
                    },
                    types: "NUMBER"
                  },
                  {
                    name: "dndate",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "Date",
                      end: "ENTER",
                      delimiter: "DELIMITER_DD"
                    },
                    types: "NUMBER"
                  },
                  {
                    name: "po",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "PO :",
                      end: "ENTER",
                      delimiter: "SPACE"
                    },
                    types: "TEXT"
                  },
                  {
                    name: "shipto",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "SHIPTO",
                      end: "ENTER",
                      delimiter: "DUE DATE"
                    },
                    types: "TEXT"
                  },
                  {
                    name: "duedate",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "DUE DATE",
                      end: "ENTER",
                      delimiter: "DELIMITER_DD"
                    },
                    types: "TEXT"
                  }
                ],
                region: {
                  name: "header",
                  x: 600,
                  y: 0,
                  width: 1200,
                  height: 430
                }
              }
            ],
            data: [],
            footer: [
              {
                name: "footer-1",
                items: [
                  {
                    name: "bank_info",
                    method: "GetLinesTextBetween",
                    params: {
                      start: "Payment via",
                      end: "",
                      lines: 3
                    },
                    types: "TEXT"
                  }
                ],
                rules: {
                  method: "GetLinesTextBetween",
                  params: {
                    start: "Payment via",
                    end: "i",
                    lines: 3
                  },
                  types: "TEXT"
                },
                region: {
                  name: "footer-1",
                  x: 0,
                  y: 0,
                  width: 600,
                  height: 1200
                }
              },
              {
                name: "footer-2",
                region_types: "image",
                page_number: 1,
                items: [
                  {
                    name: "sub_total",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "Sub Total",
                      end: ",00",
                      delimiter: "SPACE"
                    },
                    types: "CURRENCY"
                  },
                  {
                    name: "tax",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "Tax",
                      end: ",00",
                      delimiter: "SPACE"
                    },
                    types: "CURRENCY"
                  },
                  {
                    name: "invoice_balance",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "Invoice Balance",
                      end: ",00",
                      delimiter: "SPACE"
                    },
                    types: "CURRENCY"
                  }
                ],
                rules: {
                  method: "GetTextBetween",
                  params: {
                    start: "Payment",
                    end: "Page"
                  },
                  types: "TEXT"
                },
                region: {
                  name: "footer-2",
                  x: 600,
                  y: 0,
                  width: 600,
                  height: 1200
                }
              }
            ]
          },
          faktur: {
            marker: {
              text: "faktur",
              x: 103,
              y: 129,
              width: 900,
              height: 100
            },
            header: [
              {
                name: "kode",
                region_types: "image",
                page_number: 1,
                items: [
                  {
                    name: "nomor_seri",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "Nomor Seri Faktur Pajak",
                      end: "ENTER",
                      delimiter: "DELIMITER_DD"
                    },
                    types: "TEXT"
                  }
                ],
                rules: {},
                region: {
                  name: "header",
                  x: 0,
                  y: 0,
                  width: 1200,
                  height: 200
                }
              },
              {
                name: "pengusaha",
                items: [
                  {
                    name: "nama",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "Nama",
                      end: "ENTER",
                      delimiter: "DELIMITER_DD"
                    },
                    types: "TEXT"
                  },
                  {
                    name: "alamat",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "Alamat",
                      end: "ENTER",
                      delimiter: "DELIMITER_DD"
                    },
                    types: "TEXT"
                  },
                  {
                    name: "npwp",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "NPWP",
                      end: "ENTER",
                      delimiter: "DELIMITER_DD"
                    },
                    types: "TEXT"
                  }
                ],
                rules: {},
                region: {
                  name: "header",
                  x: 0,
                  y: 0,
                  width: 1200,
                  height: 300
                }
              },
              {
                name: "pembeli",
                items: [
                  {
                    name: "nama",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "Nama",
                      end: "ENTER",
                      delimiter: "DELIMITER_DD"
                    },
                    types: "TEXT"
                  },
                  {
                    name: "alamat",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "Alamat",
                      end: "ENTER",
                      delimiter: "DELIMITER_DD"
                    },
                    types: "TEXT"
                  },
                  {
                    name: "npwp",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "NPWP",
                      end: "ENTER",
                      delimiter: "DELIMITER_DD"
                    },
                    types: "TEXT"
                  }
                ],
                rules: {},
                region: {
                  name: "header-2",
                  x: 0,
                  y: 300,
                  width: 1200,
                  height: 200
                }
              }
            ],
            data: [],
            footer: [
              {
                name: "footer-1",
                region_types: "text",
                page_number: 1,
                items: [
                  {
                    name: "penggantian",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "Penggantian",
                      end: ",00",
                      delimiter: "DELIMITER_DD"
                    },
                    types: "NUMBER"
                  },
                  {
                    name: "dph",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "Harga",
                      end: ",00",
                      delimiter: "DELIMITER_DD"
                    },
                    types: "NUMBER"
                  },
                  {
                    name: "dpp",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "Pajak",
                      end: ",00",
                      delimiter: "DELIMITER_DD"
                    },
                    types: "NUMBER"
                  },
                  {
                    name: "ppnbm",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "PPnBM",
                      end: ",00",
                      delimiter: "DELIMITER_DD"
                    },
                    types: "NUMBER"
                  },
                  {
                    name: "ppn",
                    method: "GetTextWithKeyDelimiter",
                    params: {
                      start: "PPN ",
                      end: ",00",
                      delimiter: "DELIMITER_DD"
                    },
                    types: "NUMBER"
                  }
                ],
                rules: {
                  method: "GetLinesTextBetween",
                  params: {
                    start: "Harga Jual /",
                    end: "",
                    lines: 5
                  },
                  types: "TEXT"
                }
              }
            ]
          }
        },
        cropperPosition: {
          x: 0,
          y: 0,
          width: 600,
          height: 200
        },
      };
    },
    handleCropMove() {
      this.getData();
    },
    onCropperReady(cropper) {
        this.$refs.cropper.setCropBoxData({ left: 100, top: 50, width: 200, height: 100 });
    },
    preview(data) {
      this.preHtml = data.html
    },
    imgLoad(params) {
      this.optionCutter.imageload = params
    },
    paramData() {
      this.mappingTemplate();
      return {
        ti_id: this.tID,
        data_json: this.templateJson,
        pattern: this.templateCurrent.all.pattern,
        data_settings: this.templateCurrent,
        status: "ready",
      };
    },
    postData() {
      this.$http
          .put(`/api/v1/ocr/templates/file/${this.tID}`, this.paramData())
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code === 200) {
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
    },
    paramUpload() {
      var form = new FormData()
      form.append('pi_id', this.tID)
      form.append('file', this.file)
      return form
    },
    paramImageS3(currPage) {
      var form = new FormData()
      form.append('pdf_name', this.fileName)
      form.append('page', currPage)
      return form
    },
    paramImage64(image64, text_type) {
      var form = new FormData()
      form.append('image64', image64)
      form.append('text_type', text_type)
      return form
    },
    paramDocNum(companyID) {
      return {
        company_id: companyID,
        doc_type: "VUPO"
      };
    },
    getDocNum(companyID) {
      if (this.dataAll.priority === null && !this.$store.state.user.currentUser) {
        return
      }
      console.log(companyID)
      let priority = this.dataAll.priority;
      let priorityCode = "BRS"
      if (priority === "Urgent" || priority === "Very Urgent") {
        priorityCode = "VU";
      }

      let orderType = "NPO"; // Default untuk non-PO
      if (this.dataTemplate.no.toString().length > 3) {
        orderType = "PO";
      }
      let doc_type = `${priorityCode}${orderType}`
      const params = {
        company_id: this.$store.state.user.currentUser.company_id,
        doc_type: doc_type
      };
      this.$vs.loading()
      this.$http
          .get("/api/v1/galang-docnum", {params})
          .then((resp) => {
            if (resp.data) {
              let barcode = this.generateBarcodeNumber(resp.data.doc_full_num, this.dataAll.priority, this.trade_type === "Trade");
              this.dataAll.barcode = barcode;
            }
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
    },
    getSupplierData(companyID) {
      if (companyID === null) {
        return
      }
      this.$vs.loading()
      this.$http
          .post("/api/v1/supplier", this.paramDocNum(), null)
          .then((resp) => {
            console.log(resp)
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
    },
    getTextOcr(image64, varData) {
      if (image64 === null) {
        return
      }
      this.$vs.loading()
      this.$http
          .post("/api/v1/ocr/image64", this.paramImage64(image64, "TEXT"), null)
          .then((resp) => {
            this.ocr_value = resp.data;
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
    },
    generateBarcodeNumber(runningNumberStr, priority, isPO) {
      let priorityCode = "2"; // Default untuk Standard priority
      if (priority === "Urgent" || priority === "Very Urgent") {
        priorityCode = "1";
      }

      let orderType = "NN"; // Default untuk non-PO
      if (isPO) {
        orderType = "NP";
      }


      // Menghasilkan barcode number
      const barcode = `${runningNumberStr}MSDN-${orderType}${priorityCode}`;

      return barcode;
    },
    getTextQR(image64) {
      if (image64 === null) {
        return
      }
      this.$vs.loading()
      this.$http
          .post("/api/v1/ocr/qr64", this.paramImage64(image64, "TEXT"), null)
          .then((respose) => {
            this.dataAll.qr_link = respose.data;
            if (this.dataAll.qr_link) {
              let respFaktur = JSON.parse(respose.raw);
              console.log(respFaktur);

              // this.getQRData(this.dataAll.qr_link);
              if (respFaktur.resValidateFakturPm) {
                let resp = respFaktur.resValidateFakturPm;
                this.dataAll.data_qr = resp;
                this.dataTemplate.dpp = resp.jumlahDpp;
                this.dataTemplate.tax = resp.jumlahPpn;
                this.dataTemplate.tax_date = resp.tanggalFaktur;
                this.dataTemplate.dph = resp.jumlahPpn;
                this.dataTemplate.nomor_seri = resp.nomorFaktur;
                this.dataTemplate.dndate = resp.tanggalFaktur;
                // "dndate":"2023-07-05","dph":"","dpp":""
                this.$forceUpdate();
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: "success get qr data",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              } else {
                this.$vs.notify({
                  title: "Error",
                  text: "Failed to get QR data",
                  color: "danger",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                });
              }
            } else {
              this.$vs.notify({
                title: "Error",
                text: "Failed to get QR data",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            }
            this.$forceUpdate()
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
    },
    getPdfS3Image(pages) {
      pages  = "all"
      if (this.fileName === null  ) {
        return
      }
      this.imageload = false;
      this.$vs.loading()
      this.$http
          .post("/api/v1/ocr/pdf-s3/image64", this.paramImageS3(pages), null)
          .then((resp) => {
            this.imageload = true;
            if (resp.data) {
              this.optionImageThub = resp.data;
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.error,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
            if (this.selectedImageIndex === -1) {
              this.selectedImageIndex = 0;
              this.previewImage(this.selectedImageIndex,"");
            }
            this.$vs.loading.close()
            // this.optionCutter.src = `data:image/png;base64,${resp}`
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
    },
    handleReplaceTemplate() {
      window.scrollTo(0, 0);
      const templateData = {
        "supplier_id": this.dataTemplate.primary_id,
        "supplier_name": this.dataTemplate.Name,
        "id": this.dataTemplate.id
      };
      this.$emit("close", templateData);
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    mappingTemplate(){


      this.templateJson= {
        invoice: {
          marker: {
            text: "invoice",
                x: 103,
                y: 129,
                width: 900,
                height: 100
          },
          header: [
            {
              name: "header-1",
              region_types: "image",
              page_number: 1,
              items: [
                {
                  name: "no",
                  method: "GetTextWithKeyDelimiter",
                  params: {
                    start: this.templateCurrent.invoice.no.start,
                    end: this.templateCurrent.invoice.no.end,
                    delimiter: this.templateCurrent.invoice.no.delimiter,
                  },
                  types: "TEXT"
                },
                {
                  name: "dn",
                  method: "GetTextWithKeyDelimiter",
                  params: {
                    start: this.templateCurrent.invoice.dn.start,
                    end: this.templateCurrent.invoice.dn.end,
                    delimiter: this.templateCurrent.invoice.dn.delimiter,
                  },
                  types: "TEXT"
                },
                {
                  name: "dndate",
                  method: "GetTextWithKeyDelimiter",
                  params: {
                    start: this.templateCurrent.invoice.dndate.start,
                    end: this.templateCurrent.invoice.dndate.end,
                    delimiter: this.templateCurrent.invoice.dndate.delimiter,
                  },
                  types: "TEXT"
                },
                {
                  name: "po",
                  method: "GetTextWithKeyDelimiter",
                  params: {
                    start: this.templateCurrent.invoice.po.start,
                    end: this.templateCurrent.invoice.po.end,
                    delimiter: this.templateCurrent.invoice.po.delimiter,
                  },
                  types: "TEXT"
                },
                {
                  name: "shipto",
                  method: "GetTextWithKeyDelimiter",
                  params: {
                    start: this.templateCurrent.invoice.shipto.start,
                    end: this.templateCurrent.invoice.shipto.end,
                    delimiter: this.templateCurrent.invoice.shipto.delimiter,
                  },
                  types: "TEXT"
                },
                {
                  name: "duedate",
                  method: "GetTextWithKeyDelimiter",
                  params: {
                    start: this.templateCurrent.invoice.duedate.start,
                    end: this.templateCurrent.invoice.duedate.end,
                    delimiter: this.templateCurrent.invoice.duedate.delimiter,
                  },
                  types: "TEXT"
                }
              ],
              region: {
                name: "header",
                x: this.templateCurrent.invoice.header.x,
                y:  this.templateCurrent.invoice.header.y,
                width:  this.templateCurrent.invoice.header.width,
                height:  this.templateCurrent.invoice.header.height,
              }
            }
          ],
              data: [],
              footer: [
            {
              name: "footer-1",
              items: [
                {
                  name: "bank_info",
                  method: "GetLinesTextBetween",
                  params: {
                    start: "Payment via",
                    end: "",
                    lines: 3
                  },
                  types: "TEXT"
                }
              ],
              rules: {
                method: "GetLinesTextBetween",
                params: {
                  start: this.templateCurrent.invoice.header.start,
                  end:  this.templateCurrent.invoice.header.end,
                  lines: this.templateCurrent.invoice.header.line
                },
                types: "TEXT"
              },
              region: {
                name: "footer-1",
                x:  this.templateCurrent.invoice.footer.x,
                y:  this.templateCurrent.invoice.footer.y,
                width:  this.templateCurrent.invoice.footer.width,
                height:  this.templateCurrent.invoice.footer.height
              }
            },
            {
              name: "footer-2",
              region_types: "image",
              page_number: 1,
              items: [
                {
                  name: "sub_total",
                  method: "GetTextWithKeyDelimiter",
                  params: {
                    start: this.templateCurrent.invoice.sub_total.start,
                    end: this.templateCurrent.invoice.sub_total.end,
                    delimiter: this.templateCurrent.invoice.sub_total.delimiter,
                  },
                  types: "CURRENCY"
                },
                {
                  name: "tax",
                  method: "GetTextWithKeyDelimiter",
                  params: {
                    start: this.templateCurrent.invoice.tax.start,
                    end: this.templateCurrent.invoice.tax.end,
                    delimiter: this.templateCurrent.invoice.tax.delimiter
                  },
                  types: "CURRENCY"
                },
                {
                  name: "invoice_balance",
                  method: "GetTextWithKeyDelimiter",
                  params: {
                    start: this.templateCurrent.invoice.invoice_balance.start,
                    end: this.templateCurrent.invoice.invoice_balance.end,
                    delimiter: this.templateCurrent.invoice.invoice_balance.delimiter
                  },
                  types: "CURRENCY"
                }
              ],
              rules: {
                method: "GetTextBetween",
                params: {
                  start: this.templateCurrent.invoice.footer.start,
                  end: this.templateCurrent.invoice.footer.end
                },
                types: "TEXT"
              },
              region: {
                name: "footer-2",
                x: this.regions["invoice.footer"].x,
                y: this.regions["invoice.footer"].y,
                width: this.regions["invoice.footer"].width,
                height: this.regions["invoice.footer"].height
              }
            }
          ]
        },
        faktur: {
          marker: {
            text: "faktur",
                x: 103,
                y: 129,
                width: 900,
                height: 100
          },
          header: [
                    {
                      name: "kode",
                      region_types: "image",
                      page_number: 1,
                      items: [
                        {
                          name: "nomor_seri",
                          method: "GetTextWithKeyDelimiter",
                          params: {
                            start: this.templateCurrent.faktur.nomor_seri.start,
                            end: this.templateCurrent.faktur.nomor_seri.end,
                            delimiter: this.templateCurrent.faktur.nomor_seri.delimiter,
                          },
                          types: "TEXT"
                        }
                      ],
                      rules: {},
                      region: {
                        name: "header",
                        x: this.templateCurrent.faktur.header.x,
                        y: this.templateCurrent.faktur.header.y,
                        width: this.templateCurrent.faktur.header.width,
                        height: this.templateCurrent.faktur.header.height,
                      }
                    },
                    {
                      name: "pengusaha",
                      items: [
                        {
                          name: "nama",
                          method: "GetTextWithKeyDelimiter",
                          params: {
                            start:  this.templateCurrent.faktur.pengusaha_nama.start,
                            end:  this.templateCurrent.faktur.pengusaha_nama.end,
                            delimiter:  this.templateCurrent.faktur.pengusaha_nama.delimiter,
                          },
                          types: "TEXT"
                        },
                        {
                          name: "alamat",
                          method: "GetTextWithKeyDelimiter",
                          params: {
                            start:  this.templateCurrent.faktur.pengusaha_alamat.start,
                            end:  this.templateCurrent.faktur.pengusaha_alamat.end,
                            delimiter:  this.templateCurrent.faktur.pengusaha_alamat.delimiter,
                          },
                          types: "TEXT"
                        },
                        {
                          name: "npwp",
                          method: "GetTextWithKeyDelimiter",
                          params: {
                            start:  this.templateCurrent.faktur.pengusaha_npwp.start,
                            end:  this.templateCurrent.faktur.pengusaha_npwp.end,
                            delimiter:  this.templateCurrent.faktur.pengusaha_npwp.delimiter,
                           },
                          types: "TEXT"
                        }
                      ],
                      rules: {},
                      region: {
                        name: "header",
                        x: this.regions["faktur.header"].x,
                        y: this.regions["faktur.header"].y,
                        width: this.regions["faktur.header"].width,
                        height: this.regions["faktur.header"].height
                      }
                    },
                    {
                      name: "pembeli",
                      items: [
                        {
                          name: "nama",
                          method: "GetTextWithKeyDelimiter",
                          params: {
                            start: "Nama",
                            end: "ENTER",
                            delimiter: "DELIMITER_DD"
                          },
                          types: "TEXT"
                        },
                        {
                          name: "alamat",
                          method: "GetTextWithKeyDelimiter",
                          params: {
                            start: "Alamat",
                            end: "ENTER",
                            delimiter: "DELIMITER_DD"
                          },
                          types: "TEXT"
                        },
                        {
                          name: "npwp",
                          method: "GetTextWithKeyDelimiter",
                          params: {
                            start: "NPWP",
                            end: "ENTER",
                            delimiter: "DELIMITER_DD"
                          },
                          types: "TEXT"
                        }
                      ],
                      rules: {},
                      region: {
                        name: "header-2",
                        x: 0,
                        y: 300,
                        width: 1200,
                        height: 200
                      }
                    }
                  ],
              data: [],
              footer: [
                    {
                      name: "footer-1",
                      region_types: "text",
                      page_number: 1,
                      items: [
                        {
                          name: "penggantian",
                          method: "GetTextWithKeyDelimiter",
                          params: {
                            start:  this.templateCurrent.faktur.penggantian.start,
                            end:  this.templateCurrent.faktur.penggantian.end,
                            delimiter:  this.templateCurrent.faktur.penggantian.delimiter,
                          },
                          types: "NUMBER"
                        },
                        {
                          name: "dph",
                          method: "GetTextWithKeyDelimiter",
                          params: {
                            start:  this.templateCurrent.faktur.dph.start,
                            end:  this.templateCurrent.faktur.dph.end,
                            delimiter:  this.templateCurrent.faktur.dph.delimiter,
                          },
                          types: "NUMBER"
                        },
                        {
                          name: "dpp",
                          method: "GetTextWithKeyDelimiter",
                          params: {
                            start:  this.templateCurrent.faktur.dpp.start,
                            end:  this.templateCurrent.faktur.dpp.end,
                            delimiter:  this.templateCurrent.faktur.dpp.delimiter,
                          },
                          types: "NUMBER"
                        },
                        {
                          name: "ppnbm",
                          method: "GetTextWithKeyDelimiter",
                          params: {
                            start:  this.templateCurrent.faktur.ppnbm.start,
                            end:  this.templateCurrent.faktur.ppnbm.end,
                            delimiter:  this.templateCurrent.faktur.ppnbm.delimiter,
                          },
                          types: "NUMBER"
                        },
                        {
                          name: "ppn",
                          method: "GetTextWithKeyDelimiter",
                          params: {
                            start:  this.templateCurrent.faktur.ppn.start,
                            end:  this.templateCurrent.faktur.ppn.end,
                            delimiter:  this.templateCurrent.faktur.ppn.delimiter,
                          },
                          types: "NUMBER"
                        }
                      ],
                      rules: {
                        method: "GetLinesTextBetween",
                        params: {
                          start: this.templateCurrent.faktur.footer.start,
                          end: this.templateCurrent.faktur.footer.end,
                          lines: this.templateCurrent.faktur.footer.lines,
                        },
                        types: "TEXT"
                      }
                    }
          ]
        }
      };

      this.templateJson.invoice.header[0].region =  {}
      this.templateJson.invoice.header[0].rules =  {}

      if (this.templateCurrent.invoice.header.region_types==='image') {
        this.templateJson.invoice.header[0].region =  {
          name: "header",
          x: this.templateCurrent.invoice.header.x,
          y:  this.templateCurrent.invoice.header.y,
          width:  this.templateCurrent.invoice.header.width,
          height:  this.templateCurrent.invoice.header.height,
        }
      }else if (this.templateCurrent.invoice.header.region_types==='text'){
        this.templateJson.invoice.header[0].rules =  {
          method: "GetLinesTextBetween",
          params: {
            start: this.templateCurrent.invoice.header.start,
            end: this.templateCurrent.invoice.header.end,
            lines: this.templateCurrent.invoice.header.lines,
          },
          types: "TEXT"
        }
      }
      this.templateJson.faktur.header[2].region =  {}
      this.templateJson.faktur.header[2].rules =  {}
      if (this.templateCurrent.faktur.header.region_types==='image') {
        this.templateJson.faktur.header[2].region =  {
          name: "header",
          x: this.templateCurrent.faktur.header.x,
          y:  this.templateCurrent.faktur.header.y,
          width:  this.templateCurrent.faktur.header.width,
          height:  this.templateCurrent.faktur.header.height,
        }
      }else if (this.templateCurrent.faktur.header.region_types==='text'){
        this.templateJson.faktur.header[2].rules =  {
          method: "GetLinesTextBetween",
          params: {
            start: this.templateCurrent.faktur.header.start,
            end: this.templateCurrent.faktur.header.end,
            lines: this.templateCurrent.faktur.header.lines,
          },
          types: "TEXT"
        }
      }


      this.templateJson.invoice.footer[1].region =  {}
      this.templateJson.invoice.footer[1].rules =  {}

      if (this.templateCurrent.invoice.footer.region_types==='image') {
        this.templateJson.invoice.footer[1].region =  {
          name: "footer-2",
          x: this.templateCurrent.invoice.footer.x,
          y:  this.templateCurrent.invoice.footer.y,
          width:  this.templateCurrent.invoice.footer.width,
          height:  this.templateCurrent.invoice.footer.height,
        }
      }else if (this.templateCurrent.invoice.footer.region_types==='text'){
        this.templateJson.invoice.footer[1].rules =  {
          method: "GetTextBetween",
          params: {
            start: this.templateCurrent.invoice.footer.start,
            end: this.templateCurrent.invoice.footer.end,
          },
          types: "TEXT"
        }
      }


      this.templateJson.faktur.footer[0].region =  {}
      this.templateJson.faktur.footer[0].rules =  {}

      if (this.templateCurrent.faktur.footer.region_types==='image') {
        this.templateJson.faktur.footer[0].region =  {
          name: "footer-1",
          x: this.templateCurrent.faktur.footer.x,
          y:  this.templateCurrent.faktur.footer.y,
          width:  this.templateCurrent.faktur.footer.width,
          height:  this.templateCurrent.faktur.footer.height,
        }
      }else if (this.templateCurrent.faktur.footer.region_types==='text'){
        this.templateJson.faktur.footer[0].rules =  {
          method: "GetLinesTextBetween",
          params: {
            start: this.templateCurrent.faktur.footer.start,
            end: this.templateCurrent.faktur.footer.end,
            lines: this.templateCurrent.faktur.footer.lines,
          },
          types: "TEXT"
        }
      }

    },
    handleUpdate() {
      this.status_submit = true;
      if ( this.templateCurrent.all.pattern===null || this.templateCurrent.all.pattern==="" || this.templateCurrent.all.pattern==="undefined"  ){

        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Check required field",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });

        return
      }


      this.$validator.validateAll().then((result) => {
        if (result) {
          this.type_submit = "update";
          this.$vs.loading();
          this.postData();
        }
      });
    },
    getBarcode() {
      this.$forceUpdate();
    },
    ensurePathExists(obj, path) {
      const keys = path.replace(/\[(\d+)\]/g, '.$1').split('.'); // Mengubah [index] menjadi .index dan membagi path berdasarkan titik
      keys.reduce((acc, key, index) => {
        if (acc[key] === undefined || acc[key] === null) {
          acc[key] = isNaN(parseInt(keys[index + 1])) ? {} : [];
        }
        return acc[key];
      }, obj);
    },
    setDefault(obj, path, defaultValue) {
      this.ensurePathExists(obj, path);
      const keys = path.replace(/\[(\d+)\]/g, '.$1').split('.');
      const lastKey = keys.pop();
      const target = keys.reduce((acc, key) => acc[key], obj);
      if (target[lastKey] === undefined || target[lastKey] === null) {
        target[lastKey] = defaultValue;
      }
    }


  },
  beforeDestroy() {
    if (this.cropper) {
      this.cropper.destroy();
    }
  },
  mounted() {

    this.$vs.loading();
    setTimeout(async () => {
      if (this.tID && this.dataTemplate) {
        this.pageCount = this.dataTemplate.page_count
        this.fileName = this.dataTemplate.file_name;
        if (this.dataTemplate.settings.length > 4) {
          this.templateCurrent = JSON.parse(this.dataTemplate.settings);
        } else {
          // // this.templateCurrent = this.templateJson;
          // console.log(this.dataTemplate, "dataTemplate")
          if (this.dataTemplate.template_json.length > 5) {

            const dataTemplate = JSON.parse(this.dataTemplate.template_json);

            const regionDefault = {
              name: "",
              x: 0,
              y: 0,
              width: 1200,
              height: 1700
            };

            const rulesDefault = {
              method: "GetTextBetween",
              params: {
                start: "",
                end: ""
              },
              types: "TEXT"
            };

// Mengatur default untuk faktur footer
            this.setDefault(dataTemplate, 'faktur.footer[0].region', { ...regionDefault, name: "footer-1" });
            this.setDefault(dataTemplate, 'faktur.footer[0].rules', rulesDefault);

// Mengatur default untuk faktur header
            this.setDefault(dataTemplate, 'faktur.header[2].region', { ...regionDefault, name: "header-2" });
            this.setDefault(dataTemplate, 'faktur.header[2].rules', rulesDefault);

// Mengatur default untuk invoice footer
            this.setDefault(dataTemplate, 'invoice.footer[1].region', { ...regionDefault, name: "footer-1" });
            this.setDefault(dataTemplate, 'invoice.footer[1].rules', rulesDefault);
            if (!dataTemplate.invoice.footer[1].rules.params){
              dataTemplate.invoice.footer[1].rules = rulesDefault
            }

// Mengatur default untuk invoice header
            this.setDefault(dataTemplate, 'invoice.header[0].region', { ...regionDefault, name: "header-1" });
            this.setDefault(dataTemplate, 'invoice.header[0].rules', rulesDefault);
            if (!dataTemplate.invoice.header[0].rules.params){
              dataTemplate.invoice.header[0].rules=rulesDefault
            }

// Memastikan params ada dan tidak undefined
            this.setDefault(dataTemplate, 'faktur.header[2].rules.params', { start: "", end: "" });


            this.templateCurrent = {
              "all" : {
                "pattern" : this.dataTemplate.pattern
              },
              "faktur": {
                "dph": {
                  "start":dataTemplate.faktur.footer[0].items[1].params.start ,
                  "end":dataTemplate.faktur.footer[0].items[1].params.end ,
                  "delimiter":dataTemplate.faktur.footer[0].items[1].params.delimiter
                },
                "dpp": {
                  "start": dataTemplate.faktur.footer[0].items[2].params.start ,
                  "end": dataTemplate.faktur.footer[0].items[2].params.end ,
                  "delimiter":  dataTemplate.faktur.footer[0].items[2].params.delimiter
                },
                "footer": {
                  "delimiter": dataTemplate.faktur.footer[0]["rules"]["params"]["delimiter"],
                  "end": dataTemplate.faktur.footer[0]["rules"]["params"]["end"],
                  "height": dataTemplate.faktur.footer[0]["region"]["height"],
                  "lines": dataTemplate.faktur.footer[0]["rules"]["params"]["lines"],
                  "page_number": 1,
                  "region_types": "text",
                  "start": dataTemplate.faktur.footer[0]["rules"]["params"]["start"],
                  "width": dataTemplate.faktur.footer[0]["region"]["width"],
                  "x": dataTemplate.faktur.footer[0]["region"]["x"],
                  "y": dataTemplate.faktur.footer[0]["region"]["y"]
                },
                "header": {
                  "delimiter": dataTemplate.faktur.header[2]["rules"]["params"]["delimiter"],
                  "end": dataTemplate.faktur.header[2]["rules"]["params"]["end"],
                  "height": dataTemplate.faktur.header[2]["region"]["height"],
                  "lines": dataTemplate.faktur.header[2]["rules"]["params"]["lines"],
                  "page_number": 1,
                  "region_types": "text",
                  "start": dataTemplate.faktur.header[2]["rules"]["params"]["start"],
                  "width": dataTemplate.faktur.header[2]["region"]["width"],
                  "x": dataTemplate.faktur.header[2]["region"]["x"],
                  "y": dataTemplate.faktur.header[2]["region"]["y"]

                },
                "nomor_seri": {
                  "start": dataTemplate.faktur.header[0].items[0].params.start ,
                  "end": dataTemplate.faktur.header[0].items[0].params.end ,
                  "delimiter":  dataTemplate.faktur.header[0].items[0].params.delimiter
                },
                "pembeli_alamat": {
                  "start": dataTemplate.faktur.header[2].items[1].params.start ,
                  "end": dataTemplate.faktur.header[2].items[1].params.end ,
                  "delimiter":  dataTemplate.faktur.header[2].items[1].params.delimiter
                },
                "pembeli_nama": {
                  "start": dataTemplate.faktur.header[2].items[0].params.start ,
                  "end": dataTemplate.faktur.header[2].items[0].params.end ,
                  "delimiter":  dataTemplate.faktur.header[2].items[0].params.delimiter
                },
                "pembeli_npwp": {
                  "start": dataTemplate.faktur.header[2].items[2].params.start ,
                  "end": dataTemplate.faktur.header[2].items[2].params.end ,
                  "delimiter":  dataTemplate.faktur.header[2].items[2].params.delimiter
                },
                "penggantian": {
                  "delimiter": dataTemplate.faktur.footer[0].items[0].params.start ,
                  "end": dataTemplate.faktur.footer[0].items[0].params.end ,
                  "start":  dataTemplate.faktur.footer[0].items[0].params.delimiter

                },
                "pengusaha_alamat": {
                  "delimiter": "DELIMITER_DD",
                  "end": "ENTER",
                  "start": "Nama"
                },
                "pengusaha_nama": {
                  "delimiter": "DELIMITER_DD",
                  "end": "ENTER",
                  "start": "Alamat"
                },
                "pengusaha_npwp": {
                  "delimiter": "DELIMITER_DD",
                  "end": "ENTER",
                  "start": "NPWP"
                },
                "ppn": {
                  "start": dataTemplate.faktur.footer[0].items[4].params.start ,
                  "end": dataTemplate.faktur.footer[0].items[4].params.end ,
                  "delimiter":  dataTemplate.faktur.footer[0].items[4].params.delimiter
                },
                "ppnbm": {
                  "start": dataTemplate.faktur.footer[0].items[3].params.start ,
                  "end": dataTemplate.faktur.footer[0].items[3].params.end ,
                  "delimiter":  dataTemplate.faktur.footer[0].items[3].params.delimiter
                }
              },
              "invoice": {
                "dn": {
                  "start": dataTemplate.invoice.header[0].items[1].params.start ,
                  "end": dataTemplate.invoice.header[0].items[1].params.end ,
                  "delimiter":  dataTemplate.invoice.header[0].items[1].params.delimiter
                },
                "dndate": {
                  "start": dataTemplate.invoice.header[0].items[2].params.start ,
                  "end": dataTemplate.invoice.header[0].items[2].params.end ,
                  "delimiter":  dataTemplate.invoice.header[0].items[2].params.delimiter
                },
                "duedate": {
                  "start": dataTemplate.invoice.header[0].items[5].params.start ,
                  "end": dataTemplate.invoice.header[0].items[5].params.end ,
                  "delimiter":  dataTemplate.invoice.header[0].items[5].params.delimiter
                },
                "footer": {
                  "delimiter": dataTemplate.invoice.footer[1]["rules"]["params"]["delimiter"],
                  "end": dataTemplate.invoice.footer[1]["rules"]["params"]["end"],
                  "height": dataTemplate.invoice.footer[1]["region"]["height"],
                  "lines": dataTemplate.invoice.footer[1]["rules"]["params"]["lines"],
                  "page_number": 1,
                  "region_types": "text",
                  "start": dataTemplate.invoice.footer[1]["rules"]["params"]["start"],
                  "width": dataTemplate.invoice.footer[1]["region"]["width"],
                  "x": dataTemplate.invoice.footer[1]["region"]["x"],
                  "y": dataTemplate.invoice.footer[1]["region"]["y"]
                },
                "header": {
                  "delimiter": dataTemplate.invoice.header[0]["rules"]["params"]["delimiter"],
                  "end": dataTemplate.invoice.header[0]["rules"]["params"]["end"],
                  "height": dataTemplate.invoice.header[0]["region"]["height"],
                  "lines": dataTemplate.invoice.header[0]["rules"]["params"]["lines"],
                  "page_number": 1,
                  "region_types": "text",
                  "start": dataTemplate.invoice.header[0]["rules"]["params"]["start"],
                  "width": dataTemplate.invoice.header[0]["region"]["width"],
                  "x": dataTemplate.invoice.header[0]["region"]["x"],
                  "y": dataTemplate.invoice.header[0]["region"]["y"]
                },
                "invoice_balance": {
                  "start": dataTemplate.invoice.footer[1].items[2].params.start ,
                  "end": dataTemplate.invoice.footer[1].items[2].params.end ,
                  "delimiter":  dataTemplate.invoice.footer[1].items[2].params.delimiter
                },
                "no": {
                  "start": dataTemplate.invoice.header[0].items[0].params.start ,
                  "end": dataTemplate.invoice.header[0].items[0].params.end ,
                  "delimiter":  dataTemplate.invoice.header[0].items[0].params.delimiter
                },
                "po": {
                  "start": dataTemplate.invoice.header[0].items[3].params.start ,
                  "end": dataTemplate.invoice.header[0].items[3].params.end ,
                  "delimiter":  dataTemplate.invoice.header[0].items[3].params.delimiter
                },
                "shipto": {
                  "start": dataTemplate.invoice.header[0].items[4].params.start ,
                  "end": dataTemplate.invoice.header[0].items[4].params.end ,
                  "delimiter":  dataTemplate.invoice.header[0].items[4].params.delimiter
                },
                "sub_total": {
                  "start": dataTemplate.invoice.footer[1].items[0].params.start ,
                  "end": dataTemplate.invoice.footer[1].items[0].params.end ,
                  "delimiter":  dataTemplate.invoice.footer[1].items[0].params.delimiter
                },
                "tax": {
                  "start": dataTemplate.invoice.footer[1].items[1].params.start ,
                  "end": dataTemplate.invoice.footer[1].items[1].params.end ,
                  "delimiter":  dataTemplate.invoice.footer[1].items[1].params.delimiter
                }
              }
            }
          }else {

            this.templateCurrent  = {invoice: {}, faktur: {},all:{pattern:""}};
            this.setTemplateFields("invoice", [
              {name: "header", lines: 5, page_number: 5, region_types: "image"},
              {name: "footer", lines: 5, page_number: 5, region_types: "image"},
              {name: "no"},
              {name: "duedate"},
              {name: "dndate"},
              {name: "dn"},
              {name: "shipto"},
              {name: "po"},
              {name: "invoice_balance"},
              {name: "sub_total"},
              {name: "tax"}
            ]);

            this.setTemplateFields("faktur", [
              {name: "header", lines: 5, page_number: 5, region_types: "image"},
              {name: "footer", lines: 5, page_number: 5, region_types: "text"},
              {name: "nomor_seri"},
              {name: "pembeli_nama"},
              {name: "pembeli_alamat"},
              {name: "pembeli_npwp"},
              {name: "pengusaha_alamat"},
              {name: "pengusaha_nama"},
              {name: "pengusaha_npwp"},
              {name: "penggantian"},
              {name: "dph"},
              {name: "dpp"},
              {name: "ppnbm"},
              {name: "ppn"}
            ]);
            this.setTemplateEmptyValue();
          }

        }

        const createRegion = (template, section) => ({
          x: template[section].x,
          y: template[section].y,
          width: template[section].width,
          height: template[section].height,
          scaleX: 1,
          scaleY: 1,
        });

        this.regions = {
          "faktur.header": createRegion(this.templateCurrent.faktur, "header"),
          "faktur.footer": createRegion(this.templateCurrent.faktur, "footer"),
          "invoice.header": createRegion(this.templateCurrent.invoice, "header"),
          "invoice.footer": createRegion(this.templateCurrent.invoice, "footer"),
        };
        this.$forceUpdate()
        this.supplier_id = this.dataTemplate.primary_id;
        this.getOptionSupplier(this.dataTemplate.primary_id);
        let pages = Array.from({length: this.pageCount+2}, (_, i) => i + 1).join(', ');
        await this.getPdfS3Image(pages)
      }
    }, 2000)

  },
  computed: {},
  watch: {},
};
</script>

<style scoped>
div.scroll-container {
  overflow: auto;
  white-space: nowrap;
  max-width: 100%;
}

div.scroll-container img {
  display: inline-block;
  margin-right: 10px;
}


.c img {
  margin: 0.3rem 0.5rem;
}

.cc button {
  margin: 1rem 0.5rem;
}

.showBox {
  width: 100%;
  background: #dedede;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin: 5px auto;
  min-height: 50px;
}

.imgCutDownBoxContainer {
  border: 1px solid #ccc;
  background: #fff;
  text-align: center;
  overflow: hidden;
  border-radius: 5px;

}

.controller-icon-scale {
  padding: 0 8px;
  cursor: pointer;
  color: #c5cbd1;
}

.controller-icon-turn {
  color: #f9f9f9;
  margin: 0 5px;
  border-radius: 50%;
  background: #c5cbd1;
}

.icon {
  opacity: 0.5;
  cursor: pointer;
}

.icon:hover {
  opacity: 1;
}

.vc-controller:hover {
  background: rgba(255, 255, 255, 0.2);
}

.controller-icon {
  color: #f9f9f9;
  margin: 0 5px;
  border-radius: 50%;
  background: #c5cbd1;
}

.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}


.flex-container {
  display: flex;
}

.flex-item {
  padding: 10px;
}

.selected {
  border: 3px solid #968df3;
}


.cropper-area {
  width: 614px;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062CC;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area {
  width: 307px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
}
</style>
